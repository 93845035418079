import { Button } from '@mui/material';
import AnimateButton from 'ui-component/extended/AnimateButton';

const TermsAndConditions = () => {
    return (
        <div style={{ margin: '0 25%' }}>
            <div>
                <h2>Terms and Conditions of Use</h2>
                <ol>
                    <li>
                        <strong>Acceptance of Terms of Use</strong>
                        <br />
                        By using this website, you fully and unconditionally accept these terms of use. If you do not agree to these terms,
                        please do not use this site.
                    </li>
                    <li>
                        <strong>Service Description</strong>
                        <br />
                        This website is a platform connecting companies offering job opportunities with job seekers. Users can create
                        profiles, set their job search criteria, and interact with job offers that match their criteria.
                    </li>
                    <li>
                        <strong>Registration and Account Creation</strong>
                        <br />
                        To access the site's features, it is mandatory to register and create an account. You must provide accurate,
                        complete, and up-to-date information during registration.
                    </li>
                    <li>
                        <strong>Collection and Use of Personal Data</strong>
                        <br />
                        We collect and use your personal data in accordance with our privacy policy. By using this site, you consent to the
                        collection, storage, and use of your information in accordance with this policy.
                    </li>
                    <li>
                        <strong>Appropriate Use of the Site</strong>
                        <br />
                        It is prohibited to use this site to post illegal, offensive, defamatory, pornographic content, or any other
                        inappropriate content. You must also not post links to illegal or offensive websites.
                    </li>
                    <li>
                        <strong>Intellectual Property</strong>
                        <br />
                        All content on this site, including text, images, videos, and logos, is protected by intellectual property laws. You
                        agree not to copy, reproduce, or distribute this content without prior authorization.
                    </li>
                    <li>
                        <strong>Limitation of Liability</strong>
                        <br />
                        We strive to provide accurate and up-to-date information, but we cannot guarantee the accuracy, completeness, or
                        reliability of this information. By using this site, you acknowledge that we are not responsible for any direct or
                        indirect damages resulting from your use of this site.
                    </li>
                    <li>
                        <strong>Modification of Terms of Use</strong>
                        <br />
                        We reserve the right to modify these terms of use at any time. Changes will be effective upon their publication on
                        the site. It is your responsibility to regularly check the terms of use to be informed of any modifications.
                    </li>
                    <li>
                        <strong>Applicable Law and Jurisdiction</strong>
                        <br />
                        These terms of use are governed by the applicable law in your country of residence. Any disputes arising from the
                        use of this site will be subject to the competent jurisdiction of your country.
                    </li>
                </ol>
            </div>

            <div>
                <h2>Use of Personal Data</h2>
                <ol start="10">
                    <li>
                        <strong>Data Confidentiality</strong>
                        <br />
                        We are committed to protecting the confidentiality of the personal data of our website users. Data such as email
                        addresses will never be disclosed to third parties without the explicit consent of the user, unless required by law.
                    </li>
                    <li>
                        <strong>Disclosure of Information to Companies</strong>
                        <br />
                        When a user expresses interest in a job offer, only the relevant information for that offer will be shared with the
                        concerned company. This may include the user's first name as well as details related to their professional profile,
                        such as experience, skills, and qualifications.
                    </li>
                    <li>
                        <strong>User Consent</strong>
                        <br />
                        By using our platform, you consent to sharing the relevant information of your professional profile with companies
                        offering job opportunities that match your search criteria. Your email address and other personal data will only be
                        shared with your explicit consent.
                    </li>
                </ol>
            </div>

            <AnimateButton>
                <Button
                    onClick={() => {
                        window.history.back();
                    }}
                    disableElevation
                    fullWidth
                    size="large"
                    type="submit"
                    variant="contained"
                    color="secondary"
                    sx={{ backgroundColor: '#383fc9' }}
                >
                    Back
                </Button>
            </AnimateButton>
        </div>
    );
};

export default TermsAndConditions;
