// assets
import { IconKey } from '@tabler/icons';
import SettingsIcon from '@mui/icons-material/Settings';
import BusinessCenterIcon from '@mui/icons-material/BusinessCenter';
import DashboardIcon from '@mui/icons-material/Dashboard';
import CalendarMonthSharpIcon from '@mui/icons-material/CalendarMonthSharp';
import ChatRoundedIcon from '@mui/icons-material/ChatRounded';
import ReceiptLongTwoToneIcon from '@mui/icons-material/ReceiptLongTwoTone';
import SupportAgentRoundedIcon from '@mui/icons-material/SupportAgentRounded';
import AccountCircleRoundedIcon from '@mui/icons-material/AccountCircleRounded';
import EmojiPeopleIcon from '@mui/icons-material/EmojiPeople';
import PersonSearchIcon from '@mui/icons-material/PersonSearch';
import HomeIcon from '@mui/icons-material/Home';
import ChatIcon from '@mui/icons-material/Chat';
import ChatWithBadge from './ChatWithBadge';
// constant
const icons = {
    IconKey,
    SettingsIcon,
    BusinessCenterIcon,
    DashboardIcon,
    CalendarMonthSharpIcon,
    ChatRoundedIcon,
    ReceiptLongTwoToneIcon,
    SupportAgentRoundedIcon,
    AccountCircleRoundedIcon,
    HomeIcon,
    EmojiPeopleIcon,
    ChatIcon,
    ChatWithBadge
};

// ==============================|| EXTRA PAGES MENU ITEMS ||============================== //

const pages = {
    id: 'pages',
    // title: 'Pages',
    // caption: 'Pages Caption',
    type: 'group',
    children: [
        {
            id: 'home',
            title: 'Home',
            // type: 'collapse',
            type: 'item',
            url: '/home',
            icon: icons.HomeIcon
        },
        {
            id: 'profil',
            title: 'My profile',
            // type: 'collapse',
            type: 'item',
            url: '/profile/resume',
            icon: icons.AccountCircleRoundedIcon,

            children: [
                {
                    id: 'userProfileResume',
                    title: `My profile`,
                    type: 'item',
                    url: '/profile/resume',
                    breadcrumbs: false
                }
            ]
        },
        {
            id: 'interviewprep',
            title: 'Interview prep',
            type: 'collapse',
            // type: 'item',
            url: '/profile/interviewprep',
            icon: icons.AccountCircleRoundedIcon,

            children: [
                {
                    id: 'userProfileResume',
                    title: `Interview prep`,
                    type: 'item',
                    url: '/profile/interviewprep',
                    breadcrumbs: false
                },
                {
                    id: 'General',
                    title: `General Interview Questions`,
                    type: 'item',
                    url: '/profile/interviewprep?question=General',
                    breadcrumbs: false
                },
                {
                    id: 'Behavioral',
                    title: `Behavioural & Situational Questions`,
                    type: 'item',
                    url: '/profile/interviewprep?question=Behavioral',
                    breadcrumbs: false
                },
                {
                    id: 'Feedback',
                    title: `Give feedback`,
                    type: 'item',
                    url: '/profile/interviewprep?question=Feedback',
                    breadcrumbs: false
                }
            ]
        },
        {
            id: 'onboarding',
            title: 'Job preferences',
            type: 'collapse',
            icon: icons.BusinessCenterIcon,
            url: '/onboarding/edit',
            children: [
                {
                    id: 'step1',
                    title: 'Contrats',
                    type: 'item',
                    url: '/onboarding/edit?step=1',
                    breadcrumbs: false
                },
                {
                    id: 'step2',
                    title: 'Locations',
                    type: 'item',
                    url: '/onboarding/edit?step=2',
                    breadcrumbs: false
                },
                {
                    id: 'step3',
                    title: 'Job titles',
                    type: 'item',
                    url: '/onboarding/edit?step=3',
                    breadcrumbs: false
                },
                {
                    id: 'step4',
                    title: 'Experiences',
                    type: 'item',
                    url: '/onboarding/edit?step=4',
                    breadcrumbs: false
                },
                {
                    id: 'step5',
                    title: 'Skills',
                    type: 'item',
                    url: '/onboarding/edit?step=5',
                    breadcrumbs: false
                },
                {
                    id: 'step6',
                    title: 'Languages',
                    type: 'item',
                    url: '/onboarding/edit?step=6',
                    breadcrumbs: false
                },
                {
                    id: 'step7',
                    title: 'Cities',
                    type: 'item',
                    url: '/onboarding/edit?step=7',
                    breadcrumbs: false
                },
                {
                    id: 'step8',
                    title: 'Company size',
                    type: 'item',
                    url: '/onboarding/edit?step=8',
                    breadcrumbs: false
                },
                {
                    id: 'step9',
                    title: 'Salaries',
                    type: 'item',
                    url: '/onboarding/edit?step=9',
                    breadcrumbs: false
                }
            ]
        },
        // {
        //     id: 'jobs',
        //     title: 'Entretiens',
        //     type: 'item',
        //     icon: icons.BusinessCenterIcon,
        //     url: '/job/interviewRequest'
        // },
        // {
        //     id: 'mentors',
        //     title: 'Mentors',
        //     type: 'item',
        //     icon: icons.EmojiPeopleIcon,
        //     url: '/mentors'
        // },

        // {
        //     id: 'calendar',
        //     title: 'Calendrier',
        //     type: 'collapse',
        //     icon: icons.CalendarMonthSharpIcon,

        //     children: [
        //         {
        //             id: 'calendar',
        //             title: `Calendrier des entretiens`,
        //             type: 'item',
        //             url: '/calendar',
        //             breadcrumbs: false
        //         }
        //     ]
        // },

        {
            id: 'settings',
            title: 'Settings',
            type: 'item',
            url: '/user/social-profile/posts',
            icon: icons.SettingsIcon,

            children: [
                {
                    id: 'userProfile',
                    title: `Profil`,
                    type: 'item',
                    url: '/user/social-profile/posts',
                    breadcrumbs: false
                }
            ]
        }
        // ,

        // {
        //     id: 'chat',
        //     title: 'Chat Mentors',
        //     type: 'item',
        //     url: '/chat',
        //     icon: icons.ChatWithBadge
        // }
        // {
        //     id: 'support',
        //     title: 'Aide',
        //     type: 'collapse',
        //     icon: icons.SupportAgentRoundedIcon,

        //     children: [
        //         {
        //             id: 'companyProfile',
        //             title: `Profil de l'entreprise`,
        //             type: 'item',
        //             url: '/settings/company',
        //             breadcrumbs: false
        //         },
        //         {
        //             id: 'managePeople',
        //             title: 'Gestion des membres',
        //             type: 'item',
        //             url: '/settings/managePeople',
        //             breadcrumbs: false
        //         }
        //     ]
        // }
    ]
};

export default pages;
