import * as React from 'react';
import { styled } from '@mui/material/styles';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import { makeStyles } from '@material-ui/core/styles';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import DescriptionIcon from '@mui/icons-material/Description';
import { Button, Divider, Grid } from '@mui/material';
import WorkIcon from '@mui/icons-material/Work';
import { Box } from '@mui/system';

// material-ui
import { useTheme } from '@mui/material/styles';
import { useDispatch } from 'react-redux';
import { SET_JOB } from 'store/actions';
import { getJobURL } from 'utils/getJobURL';

const ExpandMore = styled((props) => {
    const { expand, ...other } = props;
    return <IconButton {...other} />;
})(({ theme, expand }) => ({
    transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
        duration: theme.transitions.duration.shortest
    })
}));

const useStyles = makeStyles((theme) => ({
    iconButtonLabel: {
        display: 'flex',
        flexDirection: 'column'
    }
}));

export default function JobCard({ job }) {
    const [expanded, setExpanded] = React.useState(false);

    const handleExpandClick = () => {
        setExpanded(!expanded);
    };
    const dispatch = useDispatch();
    const classes = useStyles();
    const theme = useTheme();

    return (
        <Card
            onClick={() => {
                dispatch({ type: SET_JOB, job: job });
                window.location.href = `/job/${getJobURL(job)}`;
            }}
            sx={{ maxWidth: 345, border: '1px solid #EEEEEE', padding: '10px', borderTop: '2px solid #1565C0' }}
        >
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    marginBottom: '20px',
                    marginTop: '10px'
                }}
            >
                <Typography variant="h4" color="text.secondary">
                    DESIGN
                </Typography>
                <Typography variant="h2" color={theme.palette.grey[900]}>
                    {job.title}
                </Typography>
            </Box>
            <Typography variant="body1" color="text.secondary">
                Candidats :
            </Typography>
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    backgroundColor: '#EEEEEE',
                    borderRadius: '10px',
                    alignItems: 'center',
                    padding: '15px'
                }}
            >
                <Box
                    component="span"
                    sx={{
                        p: 1,
                        borderLeft: '3px solid #1565C0',
                        display: 'flex',
                        flexDirection: 'column',
                        marginRight: '20px'
                    }}
                >
                    <Typography variant="body2" color="text.secondary">
                        TOTALE
                    </Typography>
                    <Typography variant="h2" color={theme.palette.grey[900]}>
                        20
                    </Typography>
                </Box>
                <Box component="span" sx={{ p: 1, borderLeft: '3px solid #2196F3', display: 'flex', flexDirection: 'column' }}>
                    <Typography variant="body2" color="text.secondary">
                        NOUVEAU
                    </Typography>
                    <Typography variant="h2" color={theme.palette.grey[900]}>
                        5
                    </Typography>
                </Box>
            </Box>

            <CardContent sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                <Typography variant="body2" color="text.secondary">
                    CDI
                </Typography>
                <Typography variant="body2" color="text.secondary">
                    Remote
                </Typography>
                <Typography variant="body2" color="text.secondary">
                    Published{' '}
                </Typography>
            </CardContent>
            <Divider />

            <Grid container direction="row" alignItems="center" justifyContent="space-between" sx={{ padding: '10px' }}>
                <Grid item>
                    <Grid container direction="row" alignItems="center">
                        <Grid item>
                            <DescriptionIcon />
                        </Grid>
                        <Grid item>Publié</Grid>
                    </Grid>
                </Grid>
                <Grid item>
                    <Grid container direction="row" alignItems="center">
                        <Grid item>See details</Grid>
                        <Grid item>
                            <KeyboardArrowRightIcon />
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Card>
    );
}
