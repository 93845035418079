import api from './api';

const chatHandler = {
    getChat(chatId) {
        return api
            .post('/chat/get', {
                chatId: chatId
            })
            .then((res) => {
                return res.data;
            });
    },
    postMessage(chatId, text) {
        return api
            .post('/chat/message', {
                chatId: chatId,
                text: text
            })
            .then((res) => {
                return res.data;
            });
    },
    getMessages(chatId, page, limit) {
        return api
            .post('/chat/message/search', {
                chatId: chatId,
                page: page,
                limit: limit
            })
            .then((res) => {
                return res.data;
            });
    }
};

export default chatHandler;
