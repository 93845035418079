import { useState, useRef, useEffect } from 'react';

import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import Cookies from 'universal-cookie';

// material-ui
import { useTheme } from '@mui/material/styles';
import {
    Avatar,
    Box,
    Card,
    CardContent,
    Chip,
    ClickAwayListener,
    Divider,
    Grid,
    InputAdornment,
    List,
    ListItemButton,
    ListItemIcon,
    ListItemText,
    OutlinedInput,
    Paper,
    Popper,
    Stack,
    Switch,
    Typography
} from '@mui/material';

// third-party
import PerfectScrollbar from 'react-perfect-scrollbar';

// project imports
import MainCard from 'ui-component/cards/MainCard';
import Transitions from 'ui-component/extended/Transitions';

// assets
import { IconLogout, IconSearch, IconSettings, IconUser } from '@tabler/icons';
import candidateHandler from 'api/candidate';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';

// ==============================|| PROFILE MENU ||============================== //

const WhatsappLink = () => {
    const theme = useTheme();
    const customization = useSelector((state) => state.customization);
    const navigate = useNavigate();

    const [sdm, setSdm] = useState(true);
    const [value, setValue] = useState('');
    const [notification, setNotification] = useState(false);
    const [selectedIndex, setSelectedIndex] = useState(-1);
    const [open, setOpen] = useState(false);
    const [openForWork, setOpenForWork] = useState(undefined);
    const [mentor, setMentor] = useState(undefined);

    const user = useSelector((state) => {
        return state.customization.user;
    });

    // const [openForWork, setOpenForWork] = useState(true);

    /**
     * anchorRef is used on different componets and specifying one type leads to other components throwing an error
     * */
    const anchorRef = useRef(null);

    const handleLogout = async (event) => {
        const cookies = new Cookies();
        cookies.set('token', '', { path: '/' });

        handleListItemClick(event, 1, '/signin');
    };

    const handleOpenForWork = async (event) => {
        const value = openForWork !== undefined ? openForWork : user.candidate.openForWork;
        candidateHandler.openForWork(!value);
        setOpenForWork(!value);
    };

    const handleMentor = async (event) => {
        const value = mentor !== undefined ? mentor : user.candidate.mentor;
        candidateHandler.mentor(!value);
        setMentor(!value);
    };

    const handleClose = (event) => {
        if (anchorRef.current && anchorRef.current.contains(event.target)) {
            return;
        }
        setOpen(false);
    };

    const handleListItemClick = (event, index, route = '') => {
        setSelectedIndex(index);
        handleClose(event);

        if (route && route !== '') {
            navigate(route);
        }
    };
    const handleToggle = () => {
        setOpen((prevOpen) => !prevOpen);
    };

    const prevOpen = useRef(open);
    useEffect(() => {
        if (prevOpen.current === true && open === false) {
            anchorRef.current.focus();
        }

        prevOpen.current = open;
    }, [open]);

    return (
        <>
            <Chip
                sx={{
                    height: '48px',
                    alignItems: 'center',
                    borderRadius: '27px',
                    transition: 'all .2s ease-in-out',
                    borderColor: theme.palette.primary.light,
                    backgroundColor: theme.palette.primary.light,
                    marginRight: '8px', // Added margin-right
                    '&:hover': {
                        borderColor: theme.palette.primary.main,
                        background: `${theme.palette.primary.main}!important`,
                        color: 'white'
                    }
                }}
                icon={<WhatsAppIcon sx={{ color: '#25D366' }} />} // WhatsApp green color
                label="Rejoin the WhatsApp Community"
                variant="outlined"
                color="primary"
                onClick={() => window.open('https://chat.whatsapp.com/IOabBPHNZd5KZC3Av30WFo', '_blank')}
            />
        </>
    );
};

export default WhatsappLink;
