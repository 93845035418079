import PropTypes from 'prop-types';

// material-ui
import { Box, Card, Grid, TextField, Typography } from '@mui/material';

// project imports
import MainCard from 'ui-component/cards/MainCard';
import { gridSpacing } from 'store/constant';
import Editor from './ReactQuill/Editor';
import SelectSKills from './Skills/Index';
import Button from '@mui/material/Button';
import SelectHiringManager from './HiringManager/Index';
import SelectLanguages from './Languages/Index';
import SelectRoleType from './RoleType/Index';
import SelectExperience from './Experience/Index';
import SelectDomaine from './Domaine/Index';
import SelectCity from './City/Index';
import { useEffect, useState } from 'react';
import jobHandler from 'api/job';
import { useParams } from 'react-router';
import { useSelector } from 'react-redux';

// ===============================|| SHADOW BOX ||=============================== //

const ShadowBox = ({ shadow }) => (
    <Card sx={{ mb: 3, boxShadow: shadow }}>
        <Box
            sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                py: 4.5,
                bgcolor: 'primary.light',
                color: 'grey.800'
            }}
        >
            <Box sx={{ color: 'inherit' }}>boxShadow: {shadow}</Box>
        </Box>
    </Card>
);

ShadowBox.propTypes = {
    shadow: PropTypes.string.isRequired
};

export default function CreateJob() {
    const j = useSelector((state) => (state.job ? state.job : {}));
    const [job, setJob] = useState(j);
    const [title, setTitle] = useState(job.title);

    const currency = 'EUR';

    const params = useParams();
    const id = +params.id;

    useEffect(() => {
        jobHandler.getOne(id).then((job) => {
            setJob(job);
            setTitle(job.title);
        });
    }, [id]);

    const saveHandler = () => {
        jobHandler
            .editJob({
                id: id,
                title: title
            })
            .then((job) => {
                window.location.href = '/job/jobsSearch';
            });
    };

    const deleteHandler = () => {
        jobHandler.deleteOne(id).then(() => {
            window.location.href = '/job/jobsSearch';
        });
    };

    return (
        <MainCard title="Créer une offre d'emploi">
            <Grid container spacing={gridSpacing} justifyContent="center" direction="column">
                <Grid item lg={12} md={12} sm={12} xs={12} sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                    <Button variant="outlined">Brouillon</Button>

                    <Button
                        variant="contained"
                        onClick={() => {
                            window.location = '/job/success';
                        }}
                    >
                        Publié
                    </Button>
                </Grid>
                <Grid item xs={12}>
                    <Grid container spacing={gridSpacing}>
                        <Grid item lg={12} md={12} sm={12} xs={12}>
                            <Typography variant="body1">Job Title</Typography>
                            <TextField
                                onChange={(e) => {
                                    setTitle(e.target.value);
                                }}
                                value={title}
                                id="outlined-basic"
                                variant="outlined"
                                fullWidth
                            />
                        </Grid>
                        <Grid item lg={12} md={12} sm={12} xs={12}>
                            <Typography variant="body1">Job Description</Typography>
                            <Editor />
                        </Grid>
                        <Grid item lg={12} md={12} sm={12} xs={12}>
                            <Typography variant="body1">Skills</Typography>
                            <SelectSKills job={job} setJob={setJob} />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item lg={12} md={12} sm={12} xs={12}>
                    <Grid container spacing={gridSpacing} sx={{ display: 'flex', flexDirection: 'row' }}>
                        <Grid item lg={6} md={6} sm={12} xs={12}>
                            <Typography variant="body1">Domaine</Typography>
                            <SelectDomaine />
                        </Grid>

                        <Grid item lg={6} md={6} sm={12} xs={12}>
                            <Typography variant="body1">Type de poste</Typography>
                            <SelectRoleType />
                        </Grid>
                        <Grid item lg={6} md={6} sm={12} xs={12}>
                            <Typography variant="body1">Expérience</Typography>
                            <SelectExperience />
                        </Grid>
                        <Grid item lg={6} md={6} sm={12} xs={12}>
                            <Typography variant="body1">Préférence de localisation</Typography>
                            <TextField id="outlined-basic" variant="outlined" fullWidth />
                        </Grid>
                        <Grid item lg={6} md={6} sm={12} xs={12}>
                            <Typography variant="body1">Ville</Typography>
                            <SelectCity />
                        </Grid>
                        <Grid item lg={6} md={6} sm={12} xs={12}>
                            <Typography variant="body1">Recruteurs</Typography>
                            <SelectHiringManager />
                        </Grid>
                        <Grid item lg={6} md={6} sm={12} xs={12}>
                            <Typography variant="body1">Recruteurs</Typography>
                            <SelectHiringManager />
                        </Grid>
                        <Grid item lg={6} md={6} sm={12} xs={12}>
                            <Typography variant="body1">Languages</Typography>
                            <SelectLanguages />
                        </Grid>
                        <Grid item lg={6} md={6} sm={12} xs={12}>
                            <Typography variant="body1">Salaire</Typography>

                            {/* <JobDescription /> */}
                        </Grid>
                        <Grid item lg={12} md={12} sm={12} xs={12} sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                            <Button variant="contained" onClick={saveHandler}>
                                Save
                            </Button>
                            <Button onClick={deleteHandler} variant="outlined">
                                Delete
                            </Button>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </MainCard>
    );
}
