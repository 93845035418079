import React, { useEffect, useState } from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import skillHandler from 'api/skill';
import MyAsyncSelect from '../../../ui-component/input/multiselect/MyAsyncSelect';
import candidateSkillHandler from 'api/candidateSkill';

import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import userHandler from 'api/user';

export default function FormAskMentors({ user, setMentorMatch }) {
    const [open, setOpen] = React.useState(false);

    const handleClickOpen = () => {
        setOpen(true);
        setText('');
    };

    const handleClose = () => {
        setOpen(false);
    };
    const [text, setText] = useState('');

    const [skills, setSkills] = useState([]);

    // useEffect(() => {
    //     skillHandler.searchSkill(1, 20, text).then((skills) => {
    //         setSkills(skills);
    //     });
    // }, [text]);

    const [age, setAge] = React.useState('');

    const handleChange = (event) => {
        setAge(event.target.value);
    };
    return (
        <div>
            <Button variant="contained" onClick={handleClickOpen}>
                Envoyer une demande
            </Button>
            <Dialog open={open} onClose={handleClose}>
                <DialogTitle>Demande de Mentor</DialogTitle>
                <DialogContent>
                    <DialogContentText sx={{ mb: 2 }}>
                        Pensez à préciser à votre futur mentor, ce que vous attendez de ce mentoring.
                    </DialogContentText>
                    <textarea
                        onChange={(e) => {
                            setText(e.target.value);
                        }}
                        id="messageMentor"
                        name="messageMentor"
                        rows="4"
                        cols="60"
                    ></textarea>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}>Cancel</Button>
                    <Button
                        variant={text.length >= 5 ? 'contained' : 'outlined'}
                        onClick={() => {
                            if (text.length < 5) {
                                return;
                            }
                            userHandler.addMentorMatch(user.id, text).then((mentorMatch) => {
                                setMentorMatch(mentorMatch);
                                handleClose();
                            });
                        }}
                    >
                        Envoyer la demande
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}
