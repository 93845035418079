import React, { useState } from 'react';
import Typography from '@mui/material/Typography';

// material-ui
import { Avatar, Box, Button, Chip, Divider, Grid, Input } from '@mui/material';
import FmdGoodIcon from '@mui/icons-material/FmdGood';

// project imports
import userHandler from 'api/user';

function MentorMatch({ match }) {
    const user = match.user;
    const [accepted, setAccepted] = useState(match.accepted);

    return (
        <Grid item sm={3} xs={12}>
            <Box
                sx={{
                    borderColor: 'white',
                    borderRadius: 3,
                    borderStyle: 'solid',
                    borderWidth: 1,
                    px: 3,
                    py: 4
                }}
            >
                <Box
                    sx={{
                        alignItems: 'center',
                        display: 'flex'
                    }}
                >
                    <Avatar src={user.image}></Avatar>
                    <Box sx={{ ml: 2 }}>
                        <Typography variant="body2">{user.firstName}</Typography>
                        <Typography color="textSecondary" variant="subtitle2">
                            {user.job}
                        </Typography>
                    </Box>
                    <Box sx={{ ml: 2 }}>
                        <Chip label="vous demande" color="secondary"></Chip>
                    </Box>
                </Box>
                <Box sx={{ display: 'flex', flexDirection: 'row', mt: 2 }}>
                    <Box sx={{ display: 'flex', flexDirection: 'row' }}>
                        <FmdGoodIcon />
                        <Typography color="textSecondary" variant="body2">
                            Paris
                        </Typography>
                    </Box>
                    <Box sx={{ ml: 2 }}>
                        <Typography color="textSecondary" variant="body2">
                            3 ans d'expérience
                        </Typography>
                    </Box>
                </Box>
                <Box
                    sx={{
                        m: -1,
                        mt: 1
                    }}
                >
                    {(user.candidate.skills.slice(0, 4) || []).map((skill) => (
                        <Chip key={skill.id} label={skill.name} sx={{ m: 1 }} />
                    ))}
                </Box>
                <Box
                    sx={{
                        mt: 2,
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between'
                    }}
                >
                    <Button variant="outlined" onClick={() => {}}>
                        Voir profil
                    </Button>

                    {accepted ? (
                        <Button variant="contained" onClick={() => {}}>
                            Send a message
                        </Button>
                    ) : (
                        <Button
                            variant="contained"
                            onClick={() => {
                                userHandler.accepthMentorMatch(user.id).then(() => {
                                    setAccepted(true);
                                });
                            }}
                        >
                            Accepter la demande
                        </Button>
                    )}
                </Box>
            </Box>
        </Grid>
    );
}

export default MentorMatch;
