import * as React from 'react';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';

export default function InterviewCard({ match }) {
    return (
        <Card
            sx={{ width: '100%', border: '1px solid #e3e5ed', cursor: 'Pointer' }}
            onClick={() => {
                window.location.href = '/job/jobsPost';
            }}
        >
            <CardMedia component="img" height="120" image={match.job.company.logo} alt="green iguana" />
            <CardContent sx={{ paddingBottom: '0px', paddingTop: '15px' }}>
                <Typography gutterBottom variant="h4" component="div">
                    {match.job.company.name}
                </Typography>
                <Typography variant="body2" color="text.secondary">
                    {match.job.title}
                </Typography>
                <Typography variant="body2" color="text.secondary">
                    £{match.job.minSalary.toLocaleString('fr-FR')} - £{match.job.maxSalary.toLocaleString('fr-FR')}
                </Typography>
            </CardContent>
            <CardActions>
                <Button size="small" variant="contained">
                    See
                </Button>
                <Button size="small" variant="contained">
                    Interested
                </Button>
                <Button size="small" variant="outlined">
                    Not interested
                </Button>
            </CardActions>
        </Card>
    );
}
